import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [genres, setGenres] = useState([]);
  const [purpose, setPurpose] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activities, setActivities] = useState([]);
  const [moodSlider, setMoodSlider] = useState(50);
  const [complexity, setComplexity] = useState('medium');
  const [decade, setDecade] = useState('any');
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    fetch('/api/genres')
      .then(res => res.json())
      .then(data => setGenres(data.genres))
      .catch(err => setError('Failed to load genres'));

    fetch('/api/activities')
      .then(res => res.json())
      .then(data => setActivities(data.activities))
      .catch(err => console.error('Failed to load activities'));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setRecommendations([]);
    setLoading(true);
    setIsExpanded(true);

    try {
      const response = await fetch('/api/recommend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          purpose, 
          moodSlider, 
          complexity, 
          decade 
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to get recommendation');
      }

      setRecommendations(data.genres);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <div className="gradient-bg">
        <div className="moving-gradient"></div>
      </div>

      <header className="main-header">
        <div className="header-content">
          <a href="/" className="logo">
            Genre<span>find</span>
          </a>
        </div>
      </header>

      <main className={`main-content ${isExpanded ? 'expanded' : ''}`}>
        <section className="hero">
          <h1>
            <span className="gradient-text">Escape</span> Your
            <br />Music Echo Chamber
          </h1>
          <p className="hero-subtitle">
            Break free from predictable algorithms. Discover genuinely different music.
          </p>
        </section>

        <section className="recommendation-form">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="input-wrapper">
                <textarea
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  placeholder="What's your music for?"
                  required
                  className="purpose-input"
                  tabIndex="1"
                />
                <div className="input-suggestions">
                  <span className="try-text">Try: </span>
                  {activities.map((activity, index) => (
                    <>
                      <button
                        key={index}
                        type="button"
                        className="activity-suggestion"
                        onClick={() => setPurpose(activity)}
                        tabIndex="-1"
                      >
                        {activity}
                      </button>
                      {index < activities.length - 1 && <span className="separator">•</span>}
                    </>
                  ))}
                </div>
                <div className="advanced-controls">
                  <button 
                    type="button" 
                    className="toggle-advanced"
                    onClick={() => setShowAdvanced(!showAdvanced)}
                  >
                    {showAdvanced ? 'Hide Advanced' : 'Show Advanced Controls'} ✨
                  </button>

                  {showAdvanced && (
                    <div className="controls-grid">
                      <div className="control-group">
                        <label>Mood Spectrum</label>
                        <input 
                          type="range" 
                          min="0" 
                          max="100" 
                          value={moodSlider}
                          onChange={(e) => setMoodSlider(e.target.value)}
                          className="mood-slider"
                        />
                        <div className="mood-labels">
                          <span>Melancholic</span>
                          <span>Euphoric</span>
                        </div>
                      </div>

                      <div className="control-group">
                        <label>Musical Complexity</label>
                        <div className="button-group">
                          {['simple', 'medium', 'complex'].map(level => (
                            <button
                              key={level}
                              className={`complexity-btn ${complexity === level ? 'active' : ''}`}
                              onClick={() => setComplexity(level)}
                              type="button"
                            >
                              {level.charAt(0).toUpperCase() + level.slice(1)}
                            </button>
                          ))}
                        </div>
                      </div>

                      <div className="control-group">
                        <label>Era Influence</label>
                        <select 
                          value={decade} 
                          onChange={(e) => setDecade(e.target.value)}
                          className="decade-select"
                        >
                          <option value="any">Any Era</option>
                          <option value="historical">Historical</option>
                          <option value="1960s">1960s</option>
                          <option value="1970s">1970s</option>
                          <option value="1980s">1980s</option>
                          <option value="1990s">1990s</option>
                          <option value="2000s">2000s</option>
                          <option value="modern">Modern</option>
                          <option value="futuristic">Futuristic</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <button type="submit" className="submit-button" disabled={loading} tabIndex="2">
                {loading ? (
                  <div className="loading-spinner">
                    <div className="spinner"></div>
                    <span>Finding your sound...</span>
                  </div>
                ) : (
                  'Discover New Genres'
                )}
              </button>
            </form>
          </div>

          {error && <div className="error-message">{error}</div>}
          
          {recommendations.length > 0 && (
            <div className="recommendation-results">
              <h3>Perfect Genres for You</h3>
              <div className="genres-container">
                {recommendations.map((genreGroup, index) => (
                  <div 
                    key={index}
                    className="genre-group"
                    style={{
                      animationDelay: `${index * 0.1}s`
                    }}
                  >
                    <a 
                      href={genreGroup.primary.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="genre-pill primary"
                    >
                      {genreGroup.primary.name}
                    </a>
                    
                    <div className="subgenres">
                      {genreGroup.related && genreGroup.related.map((related, relatedIndex) => (
                        <a 
                          key={relatedIndex}
                          href={related.url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="genre-pill secondary"
                        >
                          {related.name}
                        </a>
                      ))}
                    </div>

                    <div className="related-artists">
                      {genreGroup.artists && genreGroup.artists.map((artist, artistIndex) => (
                        <a 
                          key={artistIndex}
                          href={artist.url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="artist-pill"
                        >
                          {artist.name}
                        </a>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>

        <section className="popular-genres">
          <h2>Popular Discoveries</h2>
          <div className="genres-grid">
            {genres.map((genre, index) => (
              <div 
                key={index}
                className="genre-card"
                style={{
                  animationDelay: `${index * 0.1}s`
                }}
              >
                <a 
                  href={genre.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <div className="genre-card-content">
                    <span className="genre-name">{genre.name}</span>
                    <span className="genre-arrow">→</span>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

export default App; 